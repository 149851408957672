export const FEATURE_FLAGS = {
  HIDDEN_WIDGET_FIELDS: 'UITEAM-667-hidden-widget-fields',
  SHOW_APP_NAV: 'UITEAM-698-update-app-nav-hub',
  APP_STUDIO: 'ask-7-app-studio-app-type',
  LUCIDWORKS_AI_USAGE: 'sss-690-lucidworks-ai-usage',
  ASK_118_EM_INSTANCE: 'ASK-118-EM-INSTANCE',
  CUSTOM_MODEL_DETAIL: 'SSS-814-custom-models-shareable-link',
  LLM_PLAYGROUND: 'SSS-876-llm-playground',
  CONFIG_PROMOTION: 'CSQ-949-configuration-promotion-tab'
};

export interface LDUser {
  email: string;
  customerId: string;
}