import _ from "lodash";
import { fusionInstanceTypes } from "../../services";
import { FusionPromotionFile, FusionPromotionFileTypes, FusionPromotionResponse, FusionPromotionStates } from "./interfaces";

let mockResults: any;

const createPromotionMock = (index: number): FusionPromotionResponse => {
  const promotionStateKeys = Object.keys(FusionPromotionStates);
  return {
    "id": `d725246b-86f2-4ab3-9f43-48d04bd8c8a${index + 1}`,
    "state": promotionStateKeys[_.random(0, promotionStateKeys.length-1)],
    "sourceIntegratedSystem": {
      "id": "d725246b-86f2-4ab3-9f43-48d04bd8c8b1",
      "name": "Source Integrated System",
      "type": _.sample([fusionInstanceTypes.MANAGED_FUSION, fusionInstanceTypes.SELF_HOSTED_FUSION]),
    },
    "targetIntegratedSystem": {
      "id": "d725246b-86f2-4ab3-9f43-48d04bd8c8b2",
      "name": "Target Integrated System",
      "type": _.sample([fusionInstanceTypes.MANAGED_FUSION, fusionInstanceTypes.SELF_HOSTED_FUSION]),
    },
    "prUrl": "https://github.com/lucidworks/one-platform-deployment/pull/1486",
    "selectedFiles": [
      {
        "name": "file1.txt",
        "path": "folder/file1.txt",
        "type": FusionPromotionFileTypes.FILE,
        "downloadUrl": "https://api.github.com/repos/my-org/my-repo/contents/folder/file1.txt"
      },
      {
        "name": "aaa.json",
        "path": "folder/aaa.json",
        "type": FusionPromotionFileTypes.FILE,
        "downloadUrl": "https://api.github.com/repos/my-org/my-repo/contents/folder/aaa.json"
      }
    ],
    "createdBy": "user1234",
    "creationDate": "2025-01-07T17:58:06.028804Z",
    "approvedBy": "user5654",
    "approvedDate": "2025-01-08T17:58:06.028804Z",
  };
};

export const fusionConfigurationPromotionMockContent = (): any => {
  const itemCount = 25;
  return {
    content: _.times(itemCount, (index) => createPromotionMock(index)),
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageNumber: 0,
      pageSize: 10,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalPages: 3,
    totalElements: itemCount,
    last: false,
    size: 10,
    number: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    numberOfElements: itemCount,
    first: true,
    empty: false,
  };
};

export const fusionConfigurationPromotionMock = (filter:any = {page: 0, size: 10}): any => {
  if(_.isEmpty(mockResults)){
    mockResults = fusionConfigurationPromotionMockContent();
  }
  if(!filter?.page) {
    filter.page = 0;
  }
  const filteredContent = _.slice(mockResults.content, filter.page * filter.size, (filter.page + 1) * filter.size);

  return {
    ...mockResults,
    content: filteredContent,
    pageable: {
      ...mockResults.pageable,
      sort: {
        ...mockResults.pageable.sort,
        empty: filteredContent.length === 0,
      },
      pageNumber: filter.page,
      pageSize: filter.size,
      offset: filter.page * filter.size,
    },
    last: filter.page === mockResults.totalPages - 1,
    size: filter.size,
    number: filter.page,
    sort: {
      ...mockResults.sort,
      empty: filteredContent.length === 0
    },
    numberOfElements: filter.size,
    first: filter.page === 0,
    empty: filteredContent.length === 0,
  }
}

export const mockSimpleFiles: FusionPromotionFile[] = [
  {
    "name": "query_pipelines",
    "path": "query_pipelines",
    "downloadUrl": null,
    "type": FusionPromotionFileTypes.DIRECTORY,
  },
  {
    "name": "pipeline1.txt",
    "path": "query_pipelines/pipeline1.txt",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/branch-file1.txt",
    "type": FusionPromotionFileTypes.FILE,
  },
  {
    "name": "branch-file2.json",
    "path": "query_pipelines/branch-file2.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/branch-file2.json",
    "type": FusionPromotionFileTypes.FILE,
  }
];

export const mockSourceInstanceFiles: FusionPromotionFile[] = [
  {
    "name": "query_pipelines",
    "path": "query_pipelines",
    "downloadUrl": null,
    "type": FusionPromotionFileTypes.DIRECTORY,
  },
  {
    "name": "mypipeline-others",
    "path": "query_pipelines/mypipeline-others",
    "downloadUrl": null,
    "type": FusionPromotionFileTypes.DIRECTORY,
  },
  {
    "name": "mypipeline-others.json",
    "path": "query_pipelines/mypipeline-others/mypipeline-others.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/mypipeline-others.json",
    "type": FusionPromotionFileTypes.FILE,
  },
  {
    "name": "mypipeline-others2.json",
    "path": "query_pipelines/mypipeline-others/mypipeline-others2.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/mypipeline-others2.json",
    "type": FusionPromotionFileTypes.FILE,
  },
  {
    "name": "pipeline1.json",
    "path": "query_pipelines/pipeline1.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/pipeline1.json",
    "type": FusionPromotionFileTypes.FILE,
  },
  {
    "name": "my_pipeline_main.json",
    "path": "query_pipelines/my_pipeline_main.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/my_pipeline_main.json",
    "type": FusionPromotionFileTypes.FILE,
  },
  {
    "name": "my_pipeline_secondary.json",
    "path": "query_pipelines/my_pipeline_secondary.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/my_pipeline_secondary.json",
    "type": FusionPromotionFileTypes.FILE,
  },
  {
    "name": "index_pipelines",
    "path": "index_pipelines",
    "downloadUrl": null,
    "type": FusionPromotionFileTypes.DIRECTORY,
  },
  {
    "name": "pipeline1.json",
    "path": "index_pipelines/pipeline1.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/index_pipelines/pipeline1-others.json",
    "type": FusionPromotionFileTypes.FILE,
  },
];


export const mockSystemFiles = {
  "fbc52d23-5ff5-4f5b-a3e9-57b440247a93": mockSourceInstanceFiles,
  "others": mockSimpleFiles
}