export interface PermissionSet {
  [resource: string]: string[];
}

export interface PermissionSetGroup {
  [group: string]: PermissionSet | { [id: string]: PermissionSet };
}

export interface PermissionContext {
  customerId: string;
  appId: string;
}

export enum Resource {
  CUSTOMER = 'CUSTOMER',
  SOLUTION = 'SOLUTION',
  USER = 'USER',
  CELL = 'CELL',
  DATASOURCE = 'DATASOURCE',
  CREDENTIALS = 'CREDENTIALS',
  ANALYTICS = 'ANALYTICS',
  INTEGRATION = 'INTEGRATION',
  ML_MODEL = 'ML_MODEL',
  ML_MODEL_DEPLOYMENT = 'ML_MODEL_DEPLOYMENT',
  MANAGEDFUSION_SYSTEM = 'MANAGEDFUSION_SYSTEM',
  USERS_PROVISIONING = 'USERS_PROVISIONING',
  SERVICE_ACCOUNTS = 'SERVICE_ACCOUNTS',
  FUSION_CONFIGURATION_PROMOTION = 'FUSION_CONFIGURATION_PROMOTION',
}

export enum Action {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  WITHDRAW = 'WITHDRAW',
}

export enum Level {
  APPLICATION = 'application',
  CUSTOMER = 'customer',
  DATASOURCE = 'datasource',
  GLOBAL = 'global',
}
